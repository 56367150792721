import {YN, STATUS, CLIENT, ClientTimeUnit, PAGER} from '@constant';
import {createTimeoutPromise, dateFormat} from "@utils/utils";
import {PRE_ADVICE_MODE_OF_TRANSPORT, PRE_ADVICE_STATUS} from "../../../../../../constant";
import {
  setSelectClientList,
  setSelectOwnerList,
  setSelectSamplingList,
  setSelectSiteList,
  setTermsOwnerList
} from "../../../../../../api/common";
import {GetPageTableColumnItem} from "../../../../../../api/country";

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [{
      "key": "PRE_ADVICE_ID",
      "label": "预通知",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入预通知",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CLIENT_ID",
      "label": "客户端",
      "type": "magnifier",
      "placeholder": "请选择客户端",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false,
      defaultUpper:true,
      "sheetName": "head"
    }, {
      "key": "NOTES",
      "label": "注释",
      "type": "textarea",
      "placeholder": "请输入注释",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "col": 2
    }, {
      "key": "STATUS",
      "label": "状态 ",
      "type": "select",
      "placeholder": "请选择状态 ",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": PRE_ADVICE_STATUS,
      "formatter": ({cellValue, row, column}) => {
        let res = PRE_ADVICE_STATUS.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "OWNER_ID",
      "label": "所有者",
      "type": "magnifier",
      "placeholder": "请选择所有者",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
    }, {
      "key": "SITE_ID",
      "label": "Site ID",
      "type": "select",
      "placeholder": "请选择Site ID",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await setSelectSiteList({
          SITE: {},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.SITE_ID,
              label: item.SITE_ID
            }
          })
        }
      },
    }, {
      "key": "PRE_ADVICE_TYPE",
      "label": "类型",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入类型",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SAMPLING_TYPE",
      "label": "取样类型",
      "type": "select",
      "placeholder": "请选择取样类型",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await setSelectSamplingList({
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.SAMPLING_TYPE,
              label: item.SAMPLING_TYPE
            }
          })
        }
      },
    }, {
      "key": "STATUS_REASON_CODE",
      "label": "状态更改原因",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入状态更改原因",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUPPLIER_ID",
      "label": "供应商",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入供应商",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DUE_DSTAMP",
      "label": "到期日",
      "type": "date",
      "placeholder": "请选择到期日",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DUE_TIME",
      "label": "到期时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "到期时间",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONSIGNMENT",
      "label": "托运",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入托运",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ACTUAL_DSTAMP",
      "label": "接收日期",
      "type": "date",
      "placeholder": "请选择接收日期",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ACTUAL_TIME",
      "label": "接收时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择接收时间",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "FINISH_DSTAMP",
      "label": "完成日期 ",
      "type": "date",
      "placeholder": "请选择完成日期 ",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "FINISH_TIME",
      "label": "完成时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择完成时间",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "NUM_LINES",
      "label": "行数 ",
      "type": "numrange",
      "placeholder": "请选择行数 ",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CREATED_BY",
      "label": "创建人 ",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入创建人 ",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CREATION_DATE",
      "label": "创建日期 ",
      "type": "date",
      "placeholder": "请选择创建日期 ",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CREATION_TIME",
      "label": "创建时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择创建时间",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LAST_UPDATED_BY",
      "label": "最后更新人",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入最后更新人",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LAST_UPDATE_DATE",
      "label": "最后更新日期",
      "type": "date",
      "placeholder": "请选择最后更新日期",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LAST_UPDATE_TIME",
      "label": "最后更新时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择最后更新时间",
      "tabGroup": "mqs_preadviceheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "MASTER_PRE_ADVICE",
      "label": "Master Pre-Advice？",
      "type": "select",
      "placeholder": "请选择Master Pre-Advice？",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "CREATED_FROM_MASTER",
      "label": "从采购单创建？",
      "type": "select",
      "placeholder": "从采购单创建？",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "COLLECTION_REQD",
      "label": "需要的集合 ",
      "type": "select",
      "placeholder": "请选择需要的集合 ",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "DISALLOW_MERGE_RULES",
      "label": "忽略合并规则",
      "type": "select",
      "placeholder": "请选择忽略合并规则",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "EMAIL_CONFIRM",
      "label": "Emai确认",
      "type": "select",
      "placeholder": "Emai确认",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "DISALLOW_REPLENS",
      "label": "不允许补充 ",
      "type": "select",
      "placeholder": "请选择不允许补充 ",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "UPLOADED_WS2PC_ID",
      "label": "Uploaded_Ws2pc_Id",
      "type": "numrange",
      "placeholder": "请选择Uploaded_Ws2pc_Id",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_FILENAME",
      "label": "上传文件名",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入上传文件名",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_DSTAMP",
      "label": "上传日期",
      "type": "date",
      "placeholder": "请选择上传日期",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_TIME",
      "label": "上传时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择上传时间",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "RETURN_FLAG",
      "label": "退货",
      "type": "select",
      "placeholder": "请选择退货",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "RETURNED_ORDER_ID",
      "label": "退货订单号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入退货订单号",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TRANSFER_ORDER_ID",
      "label": "传送订单",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "传送订单",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUPPLIER_REFERENCE",
      "label": "供应商参考",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入供应商参考",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CARRIER_NAME",
      "label": "承运商名称",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入承运商名称",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CARRIER_REFERENCE",
      "label": "承运商参考 ",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入承运商参考 ",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TOD",
      "label": "交货方式",
      "type": "select",
      "placeholder": "请输入交货方式",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await setTermsOwnerList({
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.TOD,
              label: item.NOTES
            }
          })
        }
      },
    }, {
      "key": "TOD_PLACE",
      "label": "TOD交货地",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入TOD交货地",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "MODE_OF_TRANSPORT",
      "label": "运输方式",
      "type": "select",
      "placeholder": "请选择运输方式",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": PRE_ADVICE_MODE_OF_TRANSPORT,
      "formatter": ({cellValue, row, column}) => {
        let res = PRE_ADVICE_MODE_OF_TRANSPORT.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "YARD_CONTAINER_ID",
      "label": "集装箱号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入集装箱号",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "YARD_CONTAINER_TYPE",
      "label": "集装箱类型",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入集装箱类型",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "RECEIPT_CLOSED",
      "label": "关闭收货单",
      "type": "select",
      "placeholder": "请选择关闭收货单",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "UPLOADED",
      "label": "已卸载",
      "type": "select",
      "placeholder": "已卸载",
      "tabGroup": "mqs_preadviceheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "NAME",
      "label": "名称 ",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入名称 ",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONTACT",
      "label": "联系人",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入联系人",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "mqs_preadviceheader_tab21",
      "label": "地址1",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "地址1",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONTACT_PHONE",
      "label": "电话 ",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入电话 ",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "mqs_preadviceheader_tab22",
      "label": "地址2",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "地址2",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONTACT_FAX",
      "label": "传真",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入传真",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TOWN",
      "label": "城镇",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入城镇",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONTACT_EMAIL",
      "label": "电子邮件",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入电子邮件",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "COUNTY",
      "label": "县市",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入县市",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONTACT_MOBILE",
      "label": "手机",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "手机",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "POSTCODE",
      "label": "邮政编码 ",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入邮政编码 ",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": ["all"],
      "checked": false,
      defaultUpper:true,
      "sheetName": "head"
    }, {
      "key": "VAT_NUMBER",
      "label": "增值税号码",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_HEADER',
      "placeholder": "请输入增值税号码",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "COUNTRY",
      "label": "国家",
      "type": "select",
      "placeholder": "请选择国家",
      "tabGroup": "mqs_preadviceheader_tab2",
      "tabGroupName": "地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await GetPageTableColumnItem({
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.ISO3_ID,
              label: item.ISO3_ID
            }
          })
        }
      },
    }, {
      "key": "USER_DEF_TYPE_1",
      "label": "产地",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "产地",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_2",
      "label": "计费条目",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "计费条目",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_3",
      "label": "报关单位",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "报关单位",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_4",
      "label": "卡车牌号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "卡车牌号",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_5",
      "label": "报检号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "报检号",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_6",
      "label": "集装箱号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "集装箱号",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_7",
      "label": "封条号码",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "封条号码",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_8",
      "label": "客户单号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "客户单号",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_CHK_1",
      "label": "PFS卸货 ",
      "type": "select",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "PFS卸货 ",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_2",
      "label": "百胜回程货 ",
      "type": "select",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "百胜回程货 ",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_3",
      "label": "日常加班",
      "type": "select",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "日常加班",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_4",
      "label": "节假日加班",
      "type": "select",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "节假日加班",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_DATE_1",
      "label": "实际到货日期",
      "type": "date",
      "placeholder": "实际到货日期",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_1",
      "label": "司机到达时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "实际到货日期",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_2",
      "label": "用户自定义日期 2 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 2 ",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_2",
      "label": "司机离开时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "司机离开时间",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_3",
      "label": "用户自定义日期 3 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 3 ",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_3",
      "label": "开始卸货时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "开始卸货时间",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_4",
      "label": "用户自定义日期 4 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 4 ",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_3",
      "label": "完成卸货时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "完成卸货时间",
      "tabGroup": "user_def_pah_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NOTE_1",
      "label": "整改要求",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "整改要求",
      "tabGroup": "user_def_pah_tab1",
      "tabGroupName": "用户自定义的_设置2",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NOTE_2",
      "label": "整改内容",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "整改内容",
      "tabGroup": "user_def_pah_tab1",
      "tabGroupName": "用户自定义的_设置2",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_1",
      "label": "收货温度",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "收货温度",
      "tabGroup": "user_def_pah_tab1",
      "tabGroupName": "用户自定义的_设置2",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_2",
      "label": "用户自定义号码 2",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入用户自定义号码 2",
      "tabGroup": "user_def_pah_tab1",
      "tabGroupName": "用户自定义的_设置2",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_3",
      "label": "总重量（吨）",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "总重量（吨）",
      "tabGroup": "user_def_pah_tab1",
      "tabGroupName": "用户自定义的_设置2",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_4",
      "label": "收货道口",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "收货道口",
      "tabGroup": "user_def_pah_tab1",
      "tabGroupName": "用户自定义的_设置2",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ARCHIVED",
      "label": "存档",
      "type": "select",
      "placeholder": "请选择存档",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "BOOKREF_ID",
      "label": "预约号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入预约号",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CE_CONSIGNMENT_ID",
      "label": "",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CE_INVOICE_NUMBER",
      "label": "",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DIARY_DSTAMP",
      "label": "",
      "type": "date",
      "placeholder": "请选择",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LOAD_SEQUENCE",
      "label": "装车顺序",
      "type": "numrange",
      "placeholder": "请选择装车顺序",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "MASTER_PRE_ADVICE_ID",
      "label": "主单号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入主单号",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "OAP_RMA",
      "label": "",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "PRIORITY",
      "label": "优先级 ",
      "type": "numrange",
      "placeholder": "请选择优先级 ",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ROUTE_PLANNED",
      "label": "规划路线",
      "type": "select",
      "placeholder": "请选择规划路线",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "UPLOADED_HEADER_KEY",
      "label": "已上传表头关键值 ",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入已上传表头关键值 ",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_LABOR",
      "label": "",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_VVIEW",
      "label": "",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }]
  }
}