export const tab_en = {
  "mqs_preadviceheader_tab0": "mqs_preadviceheader_tab0",
  "mqs_preadviceheader_tab1": "mqs_preadviceheader_tab1",
  "mqs_preadviceheader_tab2": "mqs_preadviceheader_tab2",
  "THead": "THead",
  "user_def_pah_tab0": "user_def_pah_tab0",
  "user_def_pah_tab1": "user_def_pah_tab1"
};

export const tab_zh = {
  "mqs_preadviceheader_tab0": "综合",
  "mqs_preadviceheader_tab1": "杂项",
  "mqs_preadviceheader_tab2": "地址",
  "THead":"表头",
  "user_def_pah_tab0": "用户自定义的_设置1",
  "user_def_pah_tab1": "用户自定义的_设置2"
}
